@charset 'UTF-8';

.header {
  background: $white;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items();
  padding-left: 38px;
  padding-right: 30px;
  position: fixed;
  width: 100%;
  height: 66px;
  top: 0;
  left: 0;
  z-index: 1000;
  @include IPAD {
    padding: 0 10px;
  }
  @include SP {
    height: 50px;
    padding-left: 10px;
    padding-right: 16px;
  }
  &__btn {
    display: none;
    @include SP {
      @include flexbox();
    }
    .info {
      @include flexbox();
      @include flex-direction();
      margin-right: 15px;
      img {
        width: 100%;
        max-width: 140px;
      }
    }
    .hamberger {
      @include flexbox();
      width: 26px;
      height: 19px;
      position: relative;
      span {
        position: absolute;
        background: $gray;
        width: 100%;
        height: 2px;
        top: 50%;
        left: 0;
        @include transform_c(translateY(-50%));
        &:before,
        &:after {
          content: '';
          position: absolute;
          background: $gray;
          width: 100%;
          height: 2px;
          left: 0;
        }
        &:before {
          top: -8px;
        }
        &:after {
          top: 8px;
        }
      }
    }
  }
  &__logo {
    padding-right: 10px;
    img {
      width: 100%;
      max-width: 130px;
      @include SP {
        max-width: 90px;
      }
    }
  }
  &__content {
    @include flexbox();
    @include align-items();
    @include SP {
      background: rgba($color: $blue, $alpha: 0.9);
      @include flex-direction();
      position: fixed;
      width: 100%;
      height: 100vh;
      padding: 64px 0 0;
      top: 0;
      right: -200%;
      overflow: auto;
      z-index: 2000;
      @include transition_c(all 0.3s ease-out);
      .close {
        display: none;
        @include SP {
          position: absolute;
          display: block;
          width: 20px;
          height: 20px;
          top: 14px;
          right: 20px;
          &::before,
          &::after {
            content: '';
            position: absolute;
            background: $white;
            width: 25px;
            height: 2px;
            top: 50%;
            left: 50%;
          }
          &::before {
            @include transform_c(translate(-50%, -50%) rotate(45deg))
          }
          &::after {
            @include transform_c(translate(-50%, -50%) rotate(-45deg))
          }
        }
      }
      .gnavi {
        &__list {
          max-width: unset;
          li {
            padding: 0 15px;
            &:first-child {
              .show-unique {
                display: none;
              }
              .top-white {
                @include flexbox();
                @include justify-content();
              }
            }
            &:nth-child(1),
            &:nth-child(5) {
              a {
                border-top: 1px solid $white;
              }
            }
            &:nth-last-child(1),
            &:nth-last-child(2) {
              .show-unique {
                @include justify-content();
              }
            }
            a {
              border-bottom: 1px solid $white;
              height: 100%;
              padding: 14px 0 10px;
              .show {
                @include justify-content();
              }
              p,
              span,
              strong,
              em {
                color: $white;
              }
            }
          }
        }
        &__red {
          display: none;
        }
        &__white {
          @include flexbox();
          padding: 38px 0 14px;
          img {
            max-width: 211px;
          }
        }
      }
    }
  }
}
