.gnavi {
  &__list {
    @include flexbox();
    @include SP {
      @include flex-wrap();
      width: 100%;
      max-width: 320px;
      padding: 0 15px;
    }
    li {
      border-right: 1px solid $lightGray;
      @include flexbox();
      @include align-items();
      text-align: center;
      @include SP {
        border: none;
        width: 50%;
        padding: 0 15px 20px;
        &:nth-child(1) {
          order: 1;
          @include align-items(flex-start);
          padding-top: 2px;
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 5;
        }
        &:nth-child(4) {
          order: 7;
        }
        &:nth-child(5) {
          order: 2;
        }
        &:nth-child(6) {
          order: 4;
        }
        &:nth-child(7) {
          order: 6;
        }
        &:nth-child(8) {
          order: 8;
        }
      }
      &:first-child {
        border-left: 1px solid $lightGray;
        @include SP {
          border: none;
        }
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        @include PC {
          width: 78px;
        }
      }
      a {
        @include flexbox();
        width: 94px;
        height: 40px;
        position: relative;
        @include HOVER {
          &:hover {
            opacity: 1;
            .hide {
              @include transform_c(rotateX(0deg) translateZ(20px));
            }
            .show {
              @include transform_c(rotateX(-90deg) translateZ(20px));    
            }
            .show-unique {
              opacity: 0.7;
            }
          }
        }
        @include IPAD {
          width: 60px;
        }
        @include SP {
          padding: 0;
          width: 100%;
          height: auto;
        }
        .hide,
        .show,
        .show-unique,
        .top-white {
          @include flexbox();
          @include flex-direction();
          @include justify-content();
          @include align-items();
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          @include transition_c(all 0.3s ease-out);
          @include SP {
            @include flex-direction(row);
            @include justify-content(flex-start);
            @include flex-wrap();
            position: unset;
          }
        }
        .top-white {
          display: none;
        }
        .hide {
          @include transform_c(rotateX(90deg) translateZ(20px));
          @include SP {
            display: none;
          }
        }
        .show {
          @include transform_c(rotateX(0deg) translateZ(20px));
          @include SP {
            @include transform_c(rotateX(0deg) translateZ(0));
          }
        }
        img {
          width: 100%;
          max-width: 58px;
          @include IPAD {
            max-width: 40px;
          }
        }
        p {
          color: $blue;
          font-family: $let;
          @include font-size(18);
          letter-spacing: 0.1em;
          line-height: 20px;
          @include IPAD {
            @include font-size(12);
          }
          @include SP {
            display: inline;
          }
        }
        span {
          @include font-size(14);
          font-weight: bold;
          letter-spacing: 0.1em;
          line-height: 20px;
          @include IPAD {
            @include font-size(10);
          }
          @include SP {
            display: inline;
            @include font-size(14);
          }
        }
        strong {
          display: none;
          @include SP {
            display: block;
            @include font-size(11);
            font-weight: 300;
            margin-top: -4px;
            letter-spacing: 0.1em;
            line-height: 20px;
          }
        }
        em {
          @include font-size(14);
          font-weight: 300;
          font-style: normal;
          letter-spacing: 0.1em;
          line-height: 20px;
          @include IPAD {
            @include font-size(10);
          }
          @include SP {
            @include font-size(14);
          }
        }
      }
    }
  }
  &__red,
  &__white {
    @include flexbox();
    @include flex-direction();
    padding: 0 14px;
    @include IPAD {
      padding: 0 5px;
    }
    @include SP {
      width: 100%;
      @include align-items();
      padding: 16px 0 12px;
    }
    picture {
      @include flexbox();
      @include flex-direction();
      img {
        width: 100%;
        max-width: 132px;
        @include IPAD {
          max-width: 82px;
        }
        @include SP {
          max-width: 209px;
        }
      }
    }
  }
  &__white {
    display: none;
  }
  &__blue {
    @include flexbox();
    @include flex-direction();
    @include SP {
      width: 100%;
      @include align-items();
    }
    img {
      width: 100%;
      max-width: 226px;
      @include IPAD {
        max-width: 126px;
      }
      @include SP {
        max-width: 211px;
      }
    }
  }
}
