@charset "UTF-8";
/*-----------------------------------------------------------
font-face
------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,600&display=swap');

@font-face {
  font-family: "851 Letrogo";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../font/851letrogo.woff") format("woff");
}
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  line-height: 0;
}
.clearfix {
  display: inline-block;
}
/* exlude MacIE5 \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
p {
  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input,
textarea {
  margin: 0;
  font-size: 100%;
  resize: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}
dl,
dt,
dd,
th,
td {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-size: 100%;*/
  font-weight: normal;
  margin: 0;
  padding: 0;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
dialog {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto;
}
table img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
}
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
*,
*:before,
*:after {
  @include add_prefix(box-sizing, border-box);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {
  outline: none !important;
}
label,
select,
button {
  cursor: pointer;
}

// Remove select appearance for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }
}
@media screen\0 {
  select::-ms-expand {
    display: none;
  }
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
  outline: none;
  transition: 0.3s;
  @include PC {
    &:hover {
      text-decoration: none;
      color: #aaa;
      opacity: 0.5;
    }
  }
}
a:link,
a:visited,
a:active {
  color: $gray;
  text-decoration: none;
}
/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {
  @include ffN;
}
.ffM {
  @include ffM;
}
.ffYG {
  @include ffYG;
}
.ffYM {
  @include ffYM;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}
/*-----------------------------------------------------------
PAGE BOOT
------------------------------------------------------------*/
/* WordPress */
.wp-pagenavi {
  clear: both;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
}
.wp-pagenavi span,
.wp-pagenavi a {
  line-height: 30px;
  display: inline-block;
  margin: 0 4px;
  color: #666;
  text-align: center;
}
.wp-pagenavi a,
.wp-pagenavi span.current {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  background: #ededed;
}

.wp-pagenavi a:hover,
.wp-pagenavi span.current {
  background: #1489c3;
  color: #fff;
}
.wp-pagenavi .pages {
  margin-right: 15px;
}
/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
html {
  font-size: 62.5%;
  @include add_prefix(text-size-adjust, 100%);
  @include add_prefix(font-feature-settings, "palt");
}
body {
  font-family: $noto;
  @include font-size(16);
  width: 100%;
  color: $gray;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  letter-spacing: 0;
  height: 100%;
  word-break: break-word;

  #wrap {
    padding-top: 66px;
    overflow: hidden;
    @include SP {
      padding-top: 50px;
    }
  }

  &.opened {
    position: fixed;
    overflow: hidden;
    .header {
      &__content {
        right: 0;
      }
    }
  }
}

/*-----------------------------------------------------------
Container
------------------------------------------------------------*/
.container {
  max-width: $widthcm;
  margin: 0 auto;
}
/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.sp,
.SP {
  @include min-screen(768px) {
    display: none !important;
  }
}

.pc,
.PC {
  @include max-screen(767px) {
    display: none !important;
  }
}

.wcm {
  width: $widthcm;
  margin: auto;
}
/*-----------------------------------------------------------
Some stuff from bootstrap
------------------------------------------------------------*/
.fade {
  opacity: 0;
  @include transition_c(opacity 0.15s linear);
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include add_prefix(transition-property, "height, visibility");
  @include add_prefix(transition-duration, 0.35s);
  @include add_prefix(transition-timing-function, ease);
}
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  .close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.2;
    filter: alpha(opacity=20);
    &:hover,
    &:focus {
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
  }
  button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}
.modal.fade .modal-dialog {
  @include transform_c(translate(0, -25%));
  @include transition_c(transform 0.3s ease-out);
}
.modal.in .modal-dialog {
  @include transform_c(translate(0, 0));
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  @include add_prefix(box-shadow, 0 3px 9px rgba(0, 0, 0, 0.5));
  @include add_prefix(background-clip, padding-box);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    @include add_prefix(box-shadow, 0 5px 15px rgba(0, 0, 0, 0.5));
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tit-page-sm {
  height: 169px;
  display: block;
  width: 100%;
  background: url(../img/message/tit_page_bg.jpg) center center/100% 100% repeat-x;
  text-align: center;

  .tit {
    color: #fff;
    @include font(42, 97px, 0.1em, bold);
    padding-top: 37px;
  }

  @include SP {
    height: 56px;

    .tit {
      font-size: 20px;
      line-height: 48px;
      left: 0.05em;
      padding-top: 6px;
    }
  }
}

.ms {
  color: $white;
  padding-top: 64px;
  position: relative;
  @include SP {
    padding-top: 16px;
  }
  &::before {
    content: "";
    position: absolute;
    background: linear-gradient($darkestBlue, $lightBlue);
    width: 100%;
    height: calc(100% - 38px);
    top: 0;
    left: 0;
    z-index: -1;
    @include SP {
      height: calc(100% - 16px);
    }
  }
  .tit {
    text-align: center;
    strong {
      font-family: $let;
      @include font-size(127);
      letter-spacing: 0.1em;
      line-height: 78px;
      @include IPAD {
        @include font-size(100);
      }
      @include SP {
        @include font-size(39);
        line-height: 43px;
      }
    }
    span {
      @include font-size(99);
      font-weight: bold;
      letter-spacing: 0.1em;
      line-height: 78px;
      @include IPAD {
        @include font-size(72);
      }
      @include SP {
        @include font-size(30);
        line-height: 43px;
      }
    }
  }
  .txt {
    @include font-size(22);
    text-align: center;
    padding: 12px 15px 28px;
    letter-spacing: 0;
    line-height: 36px;
    em {
      font-style: normal;
      @include SP {
        letter-spacing: -0.5px;
      }
    }
    span {
      display: inline-block;
      width: 38px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        background: $white;
        width: 80%;
        height: 1px;
        top: -8px;
        left: 50%;
        @include transform_c(translateX(-50%));
        @include SP {
          top: -6px;
        }
      }
    }
    @include IPAD {
      @include font-size(16);
    }
    @include SP {
      @include font-size(13);
      text-align: justify;
      padding: 4px 15px 15px;
      line-height: 20px;
    }
  }
  .img {
    width: 100%;
    max-width: 1085px;
    padding: 0 15px;
    margin: 0 auto;
    @include clearfix();
    @include SP {
      padding: 0;
    }
    li {
      &:nth-child(1) {
        float: left;
        width: 60%;
        padding-right: 28px;
        @include SP {
          padding-right: 8px;
        }
      }
      &:nth-child(2) {
        float: left;
        width: 40%;
        padding-bottom: 26px;
        @include SP {
          padding-bottom: 8px;
        }
      }
      &:nth-child(3) {
        float: left;
        width: 40%;
        padding-right: 105px;
        @include SP {
          padding-right: 18px;
        }
      }
      a {
        display: block;
      }
    }
  }
}

.img-hv {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  cursor: pointer;
  @include HOVER {
    &:hover {
      opacity: 1;
      img {
        @include transform_c(scale(1.1));
      }
    }
  }
  img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
    width: 100%;
    @include transition_c(all 0.3s ease-out);
  }
}

.btn-hv {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    @include transition_c(all 0.3s ease-out);
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &--blue {
    color: $blue;
    &:link,
    &:visited,
    &:active {
      color: $blue;
    }
    @include HOVER {
      &:hover {
        color: $white;
        opacity: 1;
        &::before {
          width: 100%;
        }
      }
    }
    &::before {
      background: $blue;
    }
    &:after {
      border: 2px solid $blue;
      @include SP {
        border-width: 1px;
      }
    }
  }
  &--white {
    color: $white;
    &:link,
    &:visited,
    &:active {
      color: $white;
    }
    @include HOVER {
      &:hover {
        color: $blue;
        opacity: 1;
        &::before {
          width: 100%;
        }
      }
    }
    &::before {
      background: $white;
    }
    &:after {
      border: 2px solid $white;
      @include SP {
        border-width: 1px;
      }
    }
  }
}