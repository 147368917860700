.inview {
  // &.bounce {
  //   animation: 2s 1s bounced-word;
  // }
  &.rightWhite {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background: $whiteDark;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: 500;
      @include transition_c(all .8s ease-out);
    }
  }
  &.leftBlue {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background: $blue;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 500;
      @include transition_c(all .8s ease-out);
    }
  }
  &.on {
    &.bounce {
      animation: 2s bounced-word;
    }
    &.rightWhite {
      &::before {
        width: 0;
      }
    }
    &.leftBlue {
      &::before {
        width: 0;
      }
    }
  }

  @keyframes bounced-word {
    0% {
      transform: translate3d(0, -1000px, 0);
    }
    20% {
      transform: translate3d(0, 0, 0);
    }
    40% {
      transform: translate3d(0, -30px, 0);
    }
    50% {
      transform: translate3d(0, 0, 0);
    }
    70% {
      transform: translate3d(0, -15px, 0);
    }
    80% {
      transform: translate3d(0, 0, 0);
    }
    90% {
      transform: translate3d(0, -4px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
}