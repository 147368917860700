@charset 'UTF-8';

.footer {
  background: #e6ebee;
  padding: 68px 0 46px;
  position: relative;
  @include SP {
    padding: 26px 0 20px;
  }
  &__logo {
    text-align: center;
    margin-bottom: 35px;
    @include SP {
      margin-bottom: 25px;
    }
    a {
      display: inline-block;
      @include font-size(26);
      letter-spacing: 0.05em;
      line-height: 22px;
      @include SP {
        @include font-size(19);
        line-height: 11px;
      }
    }
  }
  &__content {
    @include flexbox();
    @include justify-content();
    @include align-items();
    @include SP {
      @include flex-wrap();
    }
  }
  &__copyright {
    color: $grayDrak;
    @include font-size(11);
    text-align: center;
    margin-top: 52px;
    letter-spacing: 0.05em;
    line-height: 16px;
    @include SP {
      @include font-size(9);
      margin-top: 32px;
      letter-spacing: 0.03em;
      line-height: 8px;
    }
  }
  &__scroll {
    position: fixed;
    bottom: 28px;
    right: -200px;
    z-index: 1000;
    @include transition_c(all 0.3s ease-out);
    @include SP {
      bottom: 22px;
    }
    &.show {
      right: 68px;
      @include SP {
        right: 22px;
      }
    }
    &.static {
      position: absolute;
      top: -94px;
      bottom: auto;
      z-index: 1;
      @include SP {
        top: -52px;
      }
    }
    a {
      background: $blue;
      display: block;
      width: 66px;
      height: 66px;
      position: relative;
      @include HOVER {
        &:hover {
          background: $white;
          border: 2px solid $blue;
          opacity: 1;
          &::before {
            border-color: $blue;
          }
        }
      }
      @include SP {
        width: 33px;
        height: 33px;
      }
      &::before {
        content: '';
        position: absolute;
        border-top: 2px solid $white;
        border-left: 2px solid $white;
        width: 16px;
        height: 16px;
        top: calc(50% + 4px);
        left: 50%;
        @include transform_c(translate(-50%, -50%) rotate(45deg));
        @include SP {
          border-width: 1px;
          top: calc(50% + 2px);
          width: 9px;
          height: 9px;
        }
      }
    }
  }
}